import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import SEO from '../components/SEO/SEO'
import website from '../../config/website'
import SliceZone from '../components/SliceZone'

export const WorkPageTemplate = ({ data }) => {
	if (!data) return null

	const pageContent = data.prismicWorkPage
	const page = pageContent.data || {}

	const { lang, type, url } = pageContent
	const activeDoc = {
		lang,
		type,
		url,
	}
	const topMenu = data.prismicTopMenu || {}

	return (
		<>
			<SEO
				title={`${pageContent.data.display_title.text} | ${website.titleAlt}`}
				pathname={typeof window === 'undefined' ? '' : location.pathname}
				desc={pageContent.data.social_description_override || data.description}
				banner={pageContent.data.social_image_override.url}
				node={pageContent}
				noIndex={pageContent.data.no_index}
			/>
			<SliceZone slices={page.body} />
		</>
	)
}

export const query = graphql`
	query workPageQuery($uid: String) {
		prismicWorkPage(uid: { eq: $uid }) {
			url
			uid
			type
			id
			lang
			data {
				display_title {
					text
				}
				social_description_override
				social_image_override {
					url
				}
				no_index
				body {
					... on PrismicWorkPageBodyInfoWithImage {
						id
						slice_label
						slice_type
						primary {
							featured_image {
								alt
								url
								thumbnails {
									mobile {
										alt
										url
									}
									tablet {
										alt
										url
									}
								}
							}
							section_title {
								html
								raw
								text
							}
							text {
								html
								raw
								text
							}
							url_hash {
								raw
							}
							theme_colour
							text_position
						}
					}
					... on PrismicWorkPageBodyTextInfo {
						id
						slice_label
						slice_type
						primary {
							left_column_text {
								html
								raw
								text
							}
							right_column_text {
								html
								raw
								text
							}
							section_title {
								html
								raw
								text
							}
							url_hash {
								raw
							}
							theme_colour
						}
					}
					... on PrismicWorkPageBodyGeneralContent {
						id
						slice_label
						slice_type
						primary {
							theme_colour
							content {
								html
								raw
								text
							}
							url_hash {
								raw
							}
						}
					}
					... on PrismicWorkPageBodyBlockquote {
						id
						slice_label
						slice_type
						primary {
							theme_colour
							text {
								html
								raw
								text
							}
							cite {
								html
								raw
								text
							}
							url_hash {
								raw
							}
						}
					}
					... on PrismicWorkPageBodyVideoEmbed {
						id
						slice_label
						slice_type
						primary {
							video {
								embed_url
								html
							}
							video_title {
								html
								raw
								text
							}
							video_description {
								html
								raw
								text
							}
							theme_colour
							url_hash {
								raw
							}
						}
					}
					... on PrismicWorkPageBodyImageWall {
						id
						slice_label
						slice_type
						primary {
							title {
								text
								raw
							}
							url_hash {
								raw
							}
						}
						items {
							image {
								alt
								url
								fluid(
									imgixParams: {
										maxWidth: 300
										w: 300
										width: 300
										fit: "crop"
										auto: "format"
									}
								) {
									src
									srcSet
								}
							}
							tile_size
						}
					}
					... on PrismicWorkPageBodyFullWidthImage {
						id
						slice_label
						slice_type
						primary {
							image {
								alt
								fluid {
									src
									srcSet
								}
							}
							image_title {
								html
								raw
								text
							}
							image_description {
								html
								raw
								text
							}
						}
					}
				}
			}
		}
		prismicTopMenu {
			...TopMenuFragment
		}
	}
`

export default withPreview(WorkPageTemplate)
